import * as Sentry from "@sentry/browser"
import {Integrations} from "@sentry/tracing"

Sentry.init({
  dsn: "https://bce658a94fdb4a278b0bf15270c13d60@o524538.ingest.sentry.io/5644196",
  integrations: [
    new Integrations.BrowserTracing(),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllText: false,
      mutationLimit: 1500,
    }),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
})

Sentry.setTag("client_side", "true")
